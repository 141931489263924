/* eslint-disable no-unused-vars */
import { createRouter, createWebHashHistory } from "vue-router";
import { routes } from "@/router/router";

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  // https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html文档地址
  // scrollBehavior 函数接收 to和 from 路由对象，如 Navigation Guards。第三个参数
  // savedPosition，只有当这是一个 popstate 导航时才可用（由浏览器的后退/前进按钮触发）
  // scrollBehavior(to, from, savedPosition) {
  //   // console.log(to, from, savedPosition);
  //   if (savedPosition) {
  //     return {
  //       // el: '#main',  // 也可以他用过页面上的DOM节点来滚动到相应的位置，使用这个方法时top和left的值对应的为改DOM元素原本位置的偏移
  //       top: savedPosition.top,
  //       behavior: "smooth", // 官方路由跳转回原本方位时的滚动动画，可能有兼容性要求，参考https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll
  //     };
  //   }
  //   // 始终滚动到顶部
  //   // return { top: 0 };
  // },
});

router.beforeEach((to, from, next) => {
  // console.log(to, from);
  console.log("路由前置守卫");
  return next();
});

router.afterEach((to, from) => {
  console.log("路由后置守卫");
});

export default router;
