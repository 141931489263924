import isView from "./homeDirectices/isView.js"; // 判定元素是否进入视图的自定义指令
import navCurrent from "./homeDirectices/navCurrent.js"; // 用于书写tab栏跳转的自定义指令

const directivesList = {
  isView, // 挂载
  navCurrent,
};

const directives = {
  install(app) {
    Object.keys(directivesList).forEach((key) => {
      app.directive(key, directivesList[key]); // 注册
    });
  },
};

export default directives; // 抛出，在全局注册
