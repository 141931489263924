/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
// !判断元素是否出现在可视区域内
export default {
  mounted(el, binding, vNode) {
    // const options = binding.value.options || {};
    const observer = new IntersectionObserver((entries) => {
      // console.log(entries);
      // console.log(entries[0].isIntersecting);
      if (entries[0].isIntersecting) {
        el.classList.add(binding.value.isShowClass);
        observer.disconnect(el); // 动画完成后移除监听
      }
    });
    observer.observe(el);
    // console.log(el);
    // console.log(binding);
  },
  updated(el, binding, vNode) {
    // console.log(el);
    // console.log(binding);
  },
};

// created ：绑定元素属性或事件监听器被应用之前调用。该指令需要附加需要在普通的 v-on 事件监听器前调用的事件监听器时，这很有用。
// beforeMounted ：当指令第一次绑定到元素并且在挂载父组件之前执行。
// mounted ：绑定元素的父组件被挂载之后调用。
// beforeUpdate ：在更新包含组件的 VNode 之前调用。
// updated ：在包含组件的 VNode 及其子组件的 VNode 更新后调用。
// beforeUnmounted ：在卸载绑定元素的父组件之前调用
// unmounted ：当指令与元素解除绑定且父组件已卸载时，只调用一次。

// binding 包含的属性具体的分别为：
// arg 自定义指令的参数名。
// value 自定义指令绑定的值。
// oldValue 指令绑定的前一个值。
// dir 被执行的钩子函数
// modifiers：一个包含修饰符的对象。
