/* eslint-disable no-unused-vars */
import router from "@/router";

export default {
  mounted(el, binding, vNode) {
    const _options = binding.value;
    const _c = el.getElementsByClassName(_options.ClassName);
    console.log(_options);
    _c[_options.currIndex].classList.add(_options.activeClass);
  },
  updated(el, binding, vNode) {
    const _options = binding.value;
    const _Oldoptions = binding.oldValue;
    const _c = el.getElementsByClassName(_options.ClassName);
    // console.log("改变了", _options.currIndex);
    // console.log(binding);
    // console.log(_Oldoptions);
    _c[_Oldoptions.currIndex].classList.remove(_options.activeClass);
    _c[_options.currIndex].classList.add(_options.activeClass);
  },
};
