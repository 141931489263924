export const routes = [
  {
    path: "/",
    redirect: "/home/home",
    // name: "home",
    // component: () => import("@/views/home/HomeView.vue"),
  },
  {
    path: "/minigame",
    name: "miniGAME",
    component: () => import("@/views/home/miniGame/RubiksCube.vue"),
  },
  {
    path: "/home",
    // name: "home",
    // redirect: "/home/home",
    component: () => import("@/views/home/HomeView"),
    children: [
      {
        path: "/home/home",
        name: "homeView",
        component: () => import("@/views/home/HomeViews/index.vue"),
      },
      {
        path: "/home/productService",
        name: "productService",
        component: () => import("@/views/home/ProductService"),
      },
      {
        path: "/home/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/home/AboutUs"),
      },
    ],
  },
];
