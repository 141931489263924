import { createApp } from "vue";
import App from "./App.vue";

import directices from "@/directices"; // 引入全局注册的自定义指令
import "element-plus/dist/index.css"; // 引入element组件样式
import "animate.css/animate.min.css"; // 引入animate样式

import router from "./router";
import store from "./store";

createApp(App, { devtools: false })
  .use(store)
  .use(router)
  .use(directices)
  .mount("#app");
